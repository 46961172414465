import {
  mdiFrequentlyAskedQuestions,
  // mdiHomeOutline,
  mdiMessageBulleted,
  mdiAccount,
  mdiChartBoxOutline,
  mdiAccountMultipleOutline,
  mdiAlphaCCircle,
  mdiCalendarAlert,
  mdiAccountBox,
  mdiTagMultiple,
  mdiAccountMultiplePlus,
  mdiCellphoneMessageOff,
  mdiCellphoneInformation,
  mdiAccountMultipleCheck
} from '@mdi/js'


export default [
  {subheader: 'USUARIO' },
  // {title: 'Inicio', icon: mdiHomeOutline, to: 'home' },
  {title: 'Mensajes', icon: mdiMessageBulleted, to: 'messages'},
  {title: 'Estadisticas', icon: mdiChartBoxOutline, to: 'stats'},
  {title: 'Perfil', icon: mdiAccount, to: 'profile'},
  { subheader: 'CAMPAÑAS' },
  {title: 'Campañas', icon: mdiCalendarAlert, to: 'campaigns'},
  {title: 'Contactos', icon: mdiAccountBox, to: 'contacts'},
  {title: 'Lotes de importación', icon: mdiAccountMultiplePlus, to: 'contact-batches'},
  {title: 'Lotes de filtrado', icon: mdiAccountMultipleCheck, to: 'filter-batches'},
  {title: 'Tags', icon: mdiTagMultiple, to: 'tags'},
  { subheader: 'CONFIGURACIONES' },
  {title: 'Usuarios', icon: mdiAccountMultipleOutline, to: 'users'},
  {title: 'Créditos', icon: mdiAlphaCCircle, to: 'credits'},
  { subheader: 'Otros' },
  {title: 'FAQ', icon: mdiFrequentlyAskedQuestions, to: 'faq'},


]
